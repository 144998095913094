import { BusinessAssetsFilingStep } from '../models/api'

export function mapToStep(stepName: string): BusinessAssetsFilingStep {
  if (stepName === 'Account Information') {
    return BusinessAssetsFilingStep.AccountInformation
  }

  if (stepName === 'Multiple Accounts') {
    return BusinessAssetsFilingStep.AssociatedAccounts
  }

  if (stepName === 'Property Asset List') {
    return BusinessAssetsFilingStep.PropertyAssetList
  }

  if (stepName === 'Leased Asset List') {
    return BusinessAssetsFilingStep.LeasedAssetList
  }

  if (stepName === 'Summary & Signature') {
    return BusinessAssetsFilingStep.SummaryAndSignature
  }

  if (stepName === 'Payment') {
    return BusinessAssetsFilingStep.Payment
  }

  throw new Error(`Could not map step "${stepName}"`)
}
