import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Loader from '../../components/Loader'
import useAuth from '../../hooks/useAuth'
import { paths } from '../../utils/paths'

export default function LoginRedirector(): JSX.Element {
  const { authInformation } = useAuth()
  const navigate = useNavigate()
  const [loggedIn, setIsLoggedIn] = useState(true)

  useEffect(() => {
    if (authInformation) {
      navigate(paths.index)
    } else {
      setIsLoggedIn(false)
    }
  }, [authInformation, navigate])

  if (!loggedIn) {
    return <Outlet />
  }

  return <Loader loading={true} />
}
