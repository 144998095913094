import DateFnsAdapter from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import Router from './components/Router'

import { AuthProvider } from './hooks/useAuth'
import theme from './utils/theme'

export default function App(): JSX.Element {
  return (
    <React.Suspense fallback="">
      <AuthProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={DateFnsAdapter}>
              <HelmetProvider>
                <QueryClientProvider client={queryClient}>
                  <CssBaseline />
                  <Router />
                  <ReactQueryDevtools />
                </QueryClientProvider>
              </HelmetProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </BrowserRouter>
      </AuthProvider>
    </React.Suspense>
  )
}

const queryClient = new QueryClient()
