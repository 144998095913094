export const paths = {
  index: '/',
  home: '/home',
  businessAssetsFiling: '/business-assets-filing/',
  accountInformation: '/business-assets-filing/account-information',
  associatedAccounts: '/business-assets-filing/multiple-accounts',
  propertyAssetList: '/business-assets-filing/property-asset-list',
  leasedAssetList: '/business-assets-filing/leased-asset-list',
  summaryAndSignature: '/business-assets-filing/summary-and-signature',
  payments: '/business-assets-filing/payments',
  thankYou: '/business-assets-filing/thank-you',
  impersonatorLogin: '/impersonator-login',
  login: '/login',
  loginWithPin: '/login-pin',
  forgotPassword: '/forgot-password',
  resetPasswordSent: '/forgot-password/sent',
  passwordReset: '/password-reset',
  signup: '/signup',
  verifyEmail: '/verify-email',
  verify: '/verify/:token',
  profile: '/profile',
  contactUs: '/contact-us',
} as const

export type Path = typeof paths[keyof typeof paths]
