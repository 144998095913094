import React from 'react'
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query'
import { useNavigate, useLocation } from 'react-router-dom'

import { SessionContext, GetSessionContextQuery, useGetSessionContextQuery } from '../generated/graphql'
import { BusinessAssetsFilingStep } from '../models/api'
import { nextStep } from '../utils/nextStep'
import { paths } from '../utils/paths'
import { mapToStep } from '../utils/stepMap'
import useAuth from './useAuth'

interface UseForcedNavigationReturnType {
  isSessionContextLoading: boolean
  sessionContextHasErrors: boolean
  sessionContextError: unknown
  refetchSessionContext: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<GetSessionContextQuery, unknown>>
}

export default function useForcedNavigation(
  accountId: number,
  jurisdiction: number,
): UseForcedNavigationReturnType {
  const location = useLocation()
  const step = getStep(location.pathname)
  const [isSessionContextLoading, setIsSessionContextLoading] = React.useState(true)

  const navigate = useNavigate()
  const { storeSessionContext } = useAuth()

  const {
    refetch: refetchSessionContext,
    isError: sessionContextHasErrors,
    error: sessionContextError,
  } = useGetSessionContextQuery(
    {
      accountId,
      jurisdiction,
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: true,
      cacheTime: 0,
      onError: () => {
        setIsSessionContextLoading(false)
      },
      onSuccess: ({ getSessionContext }) => {
        storeSessionContext?.(getSessionContext as SessionContext)

        if (getSessionContext?.completedSteps) {
          const completedSteps = getSessionContext?.completedSteps.map(mapToStep)
          const mainWorkflowCompleted = hasTheMainWorkFlowBeenCompleted(completedSteps)
          const currentStep = step ?? BusinessAssetsFilingStep.AccountInformation

          if (mainWorkflowCompleted) {
            if (currentStep === getStep(nextStep(completedSteps))) {
              setIsSessionContextLoading(false)
              return
            }

            return navigate(nextStep(completedSteps))
          }

          if (hasPermissions(currentStep, completedSteps)) {
            setIsSessionContextLoading(false)
            return
          }

          navigate(nextStep(completedSteps))
        }
      },
    },
  )

  React.useEffect(() => {
    if (window.location.href) {
      refetchSessionContext()
      setIsSessionContextLoading(true)
    }

    // eslint-disable-next-line
  }, [window.location.href])

  return { isSessionContextLoading, sessionContextHasErrors, sessionContextError, refetchSessionContext }
}

export function hasTheMainWorkFlowBeenCompleted(completedSteps: BusinessAssetsFilingStep[]): boolean {
  const hasBeenCompleted = [
    BusinessAssetsFilingStep.AccountInformation,
    BusinessAssetsFilingStep.AssociatedAccounts,
    BusinessAssetsFilingStep.PropertyAssetList,
    BusinessAssetsFilingStep.LeasedAssetList,
    BusinessAssetsFilingStep.SummaryAndSignature,
  ].every((step) => completedSteps.includes(step))

  return hasBeenCompleted
}

function hasPermissions(step: BusinessAssetsFilingStep, completedSteps: BusinessAssetsFilingStep[]): boolean {
  const permissionsByStep = {
    [BusinessAssetsFilingStep.AssociatedAccounts]: [BusinessAssetsFilingStep.AccountInformation],
    [BusinessAssetsFilingStep.PropertyAssetList]: [
      BusinessAssetsFilingStep.AccountInformation,
      BusinessAssetsFilingStep.AssociatedAccounts,
    ],
    [BusinessAssetsFilingStep.LeasedAssetList]: [
      BusinessAssetsFilingStep.AccountInformation,
      BusinessAssetsFilingStep.AssociatedAccounts,
      BusinessAssetsFilingStep.PropertyAssetList,
    ],
    [BusinessAssetsFilingStep.SummaryAndSignature]: [
      BusinessAssetsFilingStep.AccountInformation,
      BusinessAssetsFilingStep.AssociatedAccounts,
      BusinessAssetsFilingStep.PropertyAssetList,
      BusinessAssetsFilingStep.LeasedAssetList,
    ],
    [BusinessAssetsFilingStep.Payment]: [
      BusinessAssetsFilingStep.AccountInformation,
      BusinessAssetsFilingStep.AssociatedAccounts,
      BusinessAssetsFilingStep.PropertyAssetList,
      BusinessAssetsFilingStep.LeasedAssetList,
      BusinessAssetsFilingStep.SummaryAndSignature,
    ],
    [BusinessAssetsFilingStep.ThankYou]: [
      BusinessAssetsFilingStep.AccountInformation,
      BusinessAssetsFilingStep.AssociatedAccounts,
      BusinessAssetsFilingStep.PropertyAssetList,
      BusinessAssetsFilingStep.LeasedAssetList,
      BusinessAssetsFilingStep.SummaryAndSignature,
      BusinessAssetsFilingStep.Payment,
    ],
  }

  if (step === BusinessAssetsFilingStep.AccountInformation) {
    return true
  }

  const permission = permissionsByStep[step]

  return checkIfStepHasPermissions(permission, completedSteps)
}

function checkIfStepHasPermissions(
  permissions: BusinessAssetsFilingStep[],
  completedSteps: BusinessAssetsFilingStep[],
): boolean {
  return permissions.every((permission) => completedSteps.includes(permission))
}

export function getStep(pathname: string): BusinessAssetsFilingStep | null {
  if (pathname.includes(paths.accountInformation)) {
    return BusinessAssetsFilingStep.AccountInformation
  }

  if (pathname.includes(paths.associatedAccounts)) {
    return BusinessAssetsFilingStep.AssociatedAccounts
  }

  if (pathname.includes(paths.propertyAssetList)) {
    return BusinessAssetsFilingStep.PropertyAssetList
  }

  if (pathname.includes(paths.leasedAssetList)) {
    return BusinessAssetsFilingStep.LeasedAssetList
  }

  if (pathname.includes(paths.summaryAndSignature)) {
    return BusinessAssetsFilingStep.SummaryAndSignature
  }

  if (pathname.includes(paths.payments)) {
    return BusinessAssetsFilingStep.Payment
  }

  if (pathname.includes(paths.thankYou)) {
    return BusinessAssetsFilingStep.ThankYou
  }

  return null
}
