import { Account, Address, PrimaryContact } from '../generated/graphql'

export interface ValidAccount extends Account {
  yearOpened: number
  primaryContact: PrimaryContact
}

export enum BusinessAssetsFilingStep {
  AccountInformation,
  AssociatedAccounts,
  PropertyAssetList,
  LeasedAssetList,
  SummaryAndSignature,
  Payment,
  ThankYou,
}

export interface CountySettings {
  name: string
  supportUrl: string
  paymentUrl: string | null
}

export interface Classification {
  id: number
  classification: number
  description: string
}

export interface GenericAsset {
  id: number
  itemDescription: string
  units: number
  costPerUnit: number
  yearAcquired: number
}

export interface Asset extends GenericAsset {
  classification: number
}

export interface Filing {
  account?: ValidAccount
  assets?: Asset[]
  suppliesAsset?: Asset
  leasedAssets?: LeasedAsset[]
  lessors?: Lessor[]
}

export interface LeasedAsset extends GenericAsset {
  lessorId: number
  leaseNumber: number
  leaseAgreementUrl: string | null
  leaseStartMonth: number
  leaseStartYear: number
  leaseEndMonth: number
  leaseEndYear: number
  annualLeaseAmount: number
  costBeginningOfLease: number
}

export interface Lessor {
  id: number
  name: string
  address: Address
}

export interface User {
  accountId: string
  accountNumber: string
  businessName: string
  taxYear: number
  hasPaid: boolean
  completedSteps: BusinessAssetsFilingStep[]
}

export interface Jurisdiction {
  id: number
  name: string
}

export interface BusinessAccount {
  accountId: string
  accountNumber: string
  businessName: string
  jurisdiction: Jurisdiction
  situsAddress?: string
}

export interface FilingSessionContext {
  accountNumber: string
  businessName: string
  taxYear: number
  completedSteps: BusinessAssetsFilingStep[]
  delinquentTaxYears: string[]
}

interface SummaryTotalCounter {
  units: number
  cost: number
  value: number
}

type DateFormat = `${number}${number}${number}${number}-${number}${number}-${number}${number}`

export interface Summary {
  priorAssetsDeclared: SummaryTotalCounter
  assetsAdded: SummaryTotalCounter | null
  assetsRemoved: SummaryTotalCounter | null
  assetsChanged: SummaryTotalCounter | null
  supplies: SummaryTotalCounter
  netTotal: number
  otherChargesAndCredits: number | null
  lateFilingPenalty: number | null
  interest: number | null
  priorYearDelinquentTax: number | null
  payments: number | null
  exemption: number | null
  totalCost: number
  taxingAreaName: string
  taxingAreaValue: number
  tax: number
  totalTax: number
  taxableValue: number
  oathDate: DateFormat
  fillingDateline: DateFormat
  interestRate: number
  totalTaxDueDate: DateFormat
  totalTaxDueInterest: number
  annuallyTaxDueDate: DateFormat
  taxpayerDissatifiedDateline: DateFormat
  countyInfo: string
}

export interface FetchError {
  message: string
}

export interface AuthInformation {
  token: string
  userId: number
  email: string
  exp: number
  isAdmin: boolean
  impersonatorId?: number
  impersonatorJurisdictionId?: number
  impersonatorJurisdictionName?: string
}

export interface SummaryAndSignaturePayload {
  preparedAndSubmittedBy: string
  email: string
  phone: string
  pin: string
}

export type FilingStatus = 'In Progress' | 'Filed' | 'Not Filed' | 'Suspended' | 'Inactive'
