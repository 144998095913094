import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { nextStep } from '../../utils/nextStep'
import Loader from '../Loader'

export default function StepRedirector(): JSX.Element {
  const { sessionContext } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (sessionContext) {
      navigate(nextStep(sessionContext.completedSteps))
    }
  }, [sessionContext, navigate])

  return <Loader loading={true} />
}
