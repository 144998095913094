import { Box } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'

export default function Layout(): JSX.Element {

  return (
    <Box display="flex" flexDirection="column" height="100%" component="footer">
      <Box display="flex" flexDirection="column" flex={1}>
        <Outlet />
      </Box>
    </Box>
  )
}
