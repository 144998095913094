import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { getStep } from '../../hooks/useForcedNavigation'
import Stepper from '../Stepper'

export default function FilingLayout(): JSX.Element {
  const location = useLocation()

  return (
    <React.Fragment>
      <Stepper activeStep={getStep(location.pathname) ?? 0} />
      <Outlet />
    </React.Fragment>
  )
}
