import { NavigateOptions } from 'react-router'
import { NavigateFunction } from 'react-router-dom'
import { paths } from './paths'

export function goToHome(navigate: NavigateFunction): void {
  navigate(paths.home)
}

export function goToBusinessAssetFiling(navigate: NavigateFunction): void {
  navigate(paths.businessAssetsFiling)
}

export function goToAccountInformation(navigate: NavigateFunction): void {
  navigate(paths.accountInformation)
}

export function goToMultipleAccounts(navigate: NavigateFunction): void {
  navigate(paths.associatedAccounts)
}

export function goToPropertyAssetList(navigate: NavigateFunction): void {
  navigate(paths.propertyAssetList)
}

export function goToLeasedAssetList(navigate: NavigateFunction): void {
  navigate(paths.leasedAssetList)
}

export function goToSummaryAndSignature(navigate: NavigateFunction, options?: NavigateOptions): void {
  navigate(paths.summaryAndSignature, options)
}

export function goToPaymentsPage(navigate: NavigateFunction): void {
  navigate(paths.payments)
}

export function goToThankYouPage(navigate: NavigateFunction): void {
  navigate(paths.thankYou)
}

export function goToLogin(navigate: NavigateFunction): void {
  navigate(paths.login)
}

export function goToLoginWithPin(navigate: NavigateFunction): void {
  navigate(paths.loginWithPin)
}

export type GoToPage =
  | typeof goToBusinessAssetFiling
  | typeof goToAccountInformation
  | typeof goToPropertyAssetList
  | typeof goToLeasedAssetList
  | typeof goToSummaryAndSignature
  | typeof goToPaymentsPage
  | typeof goToThankYouPage
  | typeof goToLogin
  | typeof goToHome
