import { blue, green, grey, red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
// TODO: Replace this import when Material-UI v5 comes out
// https://github.com/mui-org/material-ui/issues/13394

export default createTheme({
  palette: { primary: blue, success: green, error: red },
  components: {
    MuiCssBaseline: {
      styleOverrides: { body: { height: '100vh', background: grey[200] }, '#root': { height: '100%' } },
    },
  },
})
