import React, { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'

// Pages
const Home = lazy(() => import('../../pages/BusinessAccount'))
const AccountInformation = lazy(() => import('../../pages/AccountInformation'))
const AssociatedAccounts = lazy(() => import('../../pages/AssociatedAccounts'))
const ContactUs = lazy(() => import('../../pages/ContactUs'))
const EmailConfirmation = lazy(() => import('../../pages/EmailConfirmation'))
const ForgotPassword = lazy(() => import('../../pages/ForgotPassword'))
const ResetPasswordRequest = lazy(() => import('../../pages/ForgotPassword/ResetPasswordRequested'))
const PasswordReset = lazy(() => import('../../pages/PasswordReset'))
const ImpersonatorLogin = lazy(() => import('../../pages/ImpersonatorLogin'))
const LeasedAssetList = lazy(() => import('../../pages/LeasedAssetList'))
const Login = lazy(() => import('../../pages/Login'))
const LoginWithPin = lazy(() => import('../../pages/LoginWithPin'))
const Payments = lazy(() => import('../../pages/Payment'))
const PropertyAssetList = lazy(() => import('../../pages/PropertyAssetList'))
const Signup = lazy(() => import('../../pages/Signup'))
const SummaryAndSignature = lazy(() => import('../../pages/SummaryAndSignature'))
const ThankYou = lazy(() => import('../../pages/ThankYou'))
const Profile = lazy(() => import('../../pages/UserProfile'))
const VerifyEmail = lazy(() => import('../../pages/VerifyEmail'))

// Guards
import HomeRedirector from '../../pages/Home/HomeRedirector'
import LoginRedirector from '../../pages/Login/LoginRedirector'
import { paths } from '../../utils/paths'
import Layout from '../Layout'
import FilingLayout from '../Layout/FilingLayout'
import ProtectedFilingRoute from '../ProtectedFilingRoute'
import ProtectedRoute from '../ProtectedRoute'
import SessionContextRemover from '../SessionContextRemover'
import StepRedirector from '../StepRedirector'
import Fallback from '../SuspenseFallback'

export default function Router(): JSX.Element {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path={paths.index} element={<HomeRedirector />} />
        <Route
          path={paths.impersonatorLogin}
          element={
            <Suspense fallback={<Fallback />}>
              <ImpersonatorLogin />
            </Suspense>
          }
        />
        <Route element={<LoginRedirector />}>
          <Route
            path={paths.loginWithPin}
            element={
              <Suspense fallback={<Fallback />}>
                <LoginWithPin />
              </Suspense>
            }
          />
          <Route
            path={paths.login}
            element={
              <Suspense fallback={<Fallback />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path={paths.forgotPassword}
            element={
              <Suspense fallback={<Fallback />}>
                <ForgotPassword />
              </Suspense>
            }
          />
          <Route
            path={paths.resetPasswordSent}
            element={
              <Suspense fallback={<Fallback />}>
                <ResetPasswordRequest />
              </Suspense>
            }
          />
          <Route
            path={paths.passwordReset}
            element={
              <Suspense fallback={<Fallback />}>
                <PasswordReset />
              </Suspense>
            }
          />
          <Route
            path={paths.signup}
            element={
              <Suspense fallback={<Fallback />}>
                <Signup />
              </Suspense>
            }
          />
          <Route
            path={paths.verifyEmail}
            element={
              <Suspense fallback={<Fallback />}>
                <VerifyEmail />
              </Suspense>
            }
          />
          <Route
            path={paths.verify}
            element={
              <Suspense fallback={<Fallback />}>
                <EmailConfirmation />
              </Suspense>
            }
          />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route element={<SessionContextRemover />}>
            <Route
              path={paths.home}
              element={
                <Suspense fallback={<Fallback />}>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path={paths.profile}
              element={
                <Suspense fallback={<Fallback />}>
                  <Profile />
                </Suspense>
              }
            />
            <Route
              path={paths.contactUs}
              element={
                <Suspense fallback={<Fallback />}>
                  <ContactUs />
                </Suspense>
              }
            />
          </Route>
          <Route element={<ProtectedFilingRoute />}>
            <Route element={<FilingLayout />}>
              <Route
                path={paths.businessAssetsFiling}
                element={
                  <Suspense fallback={<Fallback />}>
                    <StepRedirector />
                  </Suspense>
                }
              />
              <Route
                path={paths.accountInformation}
                element={
                  <Suspense fallback={<Fallback />}>
                    <AccountInformation />
                  </Suspense>
                }
              />
              <Route
                path={paths.associatedAccounts}
                element={
                  <Suspense fallback={<Fallback />}>
                    <AssociatedAccounts />
                  </Suspense>
                }
              />
              <Route
                path={paths.propertyAssetList}
                element={
                  <Suspense fallback={<Fallback />}>
                    <PropertyAssetList />
                  </Suspense>
                }
              />
              <Route
                path={paths.leasedAssetList}
                element={
                  <Suspense fallback={<Fallback />}>
                    <LeasedAssetList />
                  </Suspense>
                }
              />
              <Route
                path={paths.summaryAndSignature}
                element={
                  <Suspense fallback={<Fallback />}>
                    <SummaryAndSignature />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path={paths.payments}
              element={
                <Suspense fallback={<Fallback />}>
                  <Payments />
                </Suspense>
              }
            />
            <Route
              path={paths.thankYou}
              element={
                <Suspense fallback={<Fallback />}>
                  <ThankYou />
                </Suspense>
              }
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}
