import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

export default function SessionContextRemover(): JSX.Element {
  const { businessAccount, sessionContext, removeSessionContext } = useAuth()

  useEffect(() => {
    if (businessAccount || sessionContext) {
      removeSessionContext?.()
    }
  }, [businessAccount, sessionContext, removeSessionContext])

  return <Outlet />
}
