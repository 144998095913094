import { CountySettings, User } from '../models/api'

export function getCountySettings(): CountySettings | undefined {
  const countySettingsString = localStorage.getItem(keys.countySettings)

  if (!countySettingsString) {
    return
  }

  return JSON.parse(countySettingsString)
}

export function setCountySettings(countySettings: CountySettings): void {
  localStorage.setItem(keys.countySettings, JSON.stringify(countySettings))
}

function removeCountySettings(): void {
  localStorage.removeItem(keys.countySettings)
}

export function getUser(): User | undefined {
  const userString = localStorage.getItem(keys.user)

  if (!userString) {
    return
  }

  return JSON.parse(userString)
}

export function setUser(user: User): void {
  localStorage.setItem(keys.user, JSON.stringify(user))
}

function removeUser(): void {
  localStorage.removeItem(keys.user)
}

function removeAuthInformation(): void {
  localStorage.removeItem(keys.authInformation)
}

export function logout(): void {
  removeUser()
  removeCountySettings()
  removeAuthInformation()

  window.location.href = '/login'
}

const keys = {
  authInformation: 'authInformation',
  countySettings: 'countySettings',
  user: 'user',
} as const
