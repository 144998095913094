import React from 'react'
import ReactDOM from 'react-dom'
import '@fontsource/roboto'

import App from './App'
import './utils/i18n'
import './libs/sentry'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
