import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuIcon from '@mui/icons-material/Menu'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Theme } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { FilingSessionContext } from '../../models/api'
import { paths } from '../../utils/paths'
import DelinquentBanner from '../DelinquentBanner'

interface Props {
  onMenuClick?: React.MouseEventHandler<HTMLButtonElement>
}

export default function Header(props: Props): JSX.Element {
  const classes = useStyles()
  const { t } = useTranslation()
  const { sessionContext } = useAuth()
  const { showDelinquentNoticeBanner, setShowDelinquentNoticeBanner } = useAuth()
  const headerOptions = {
    accountNumber: sessionContext?.accountNumber,
    businessName: sessionContext?.businessName,
  }

  const onDismissDelinquentBannerClose = (): void => setShowDelinquentNoticeBanner?.(false)

  return (
    <React.Fragment>
      <AppBar position="static" component="header">
        <Toolbar>
          <IconButton
            onClick={props.onMenuClick}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label={t('showNavigationMenu')}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="h2" className={classes.title}>
            {sessionContext ? t('businessAssetFilingHeader', headerOptions) : t('businessAssetsFiling')}
          </Typography>
          <Button
            component={Link}
            to={paths.contactUs}
            color="inherit"
            aria-label={t('contactUs')}
            endIcon={<OpenInNewIcon />}
          >
            {t('contactUs')}
          </Button>
          <Button component={Link} to={paths.profile} color="inherit" aria-label={t('userProfile')}>
            <AccountCircle />
          </Button>
        </Toolbar>
      </AppBar>

      {Boolean(sessionContext?.delinquentTaxYears?.length) && showDelinquentNoticeBanner && (
        <DelinquentBanner delinquentTaxYears={(sessionContext as FilingSessionContext).delinquentTaxYears} onClose={onDismissDelinquentBannerClose} />
      )}
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  menuButton: { marginRight: theme.spacing(2) },
  title: { flexGrow: 1 },
}))
