import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/Loader'
import useAuth from '../../hooks/useAuth'
import { paths } from '../../utils/paths'

export default function HomeRedirector(): JSX.Element {
  const { authInformation, logout } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!authInformation) {
      logout?.()
      navigate(paths.loginWithPin)
    } else {
      navigate(paths.home)
    }
  }, [authInformation, navigate, logout])

  return <Loader loading={true} />
}
