/* eslint-disable indent */
import { endpointUrl } from '../config'
import useAuth from '../hooks/useAuth'
import { logout } from '../utils/localStorage'

type HeaderType = RequestInit['headers'] & {
  'Content-Type': string
  authorization?: string
}

export const withCustomUrl =
  (url: string) =>
  <TData, TVariables>(
    query: string,
    options?: RequestInit['headers'],
  ): ((variables?: TVariables) => Promise<TData>) => {
    const { authInformation } = useAuth()
    const headers: HeaderType = {
      'Content-Type': 'application/json',
      ...options,
    }

    headers['authorization'] = `Bearer ${authInformation?.token}`

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    return async (variables?: TVariables) => {
      const res = await fetch(url || endpointUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          query,
          variables,
        }),
      })

      const json = await res.json()

      if (json.errors?.[0]?.extensions?.code === 'UNAUTHENTICATED') {
        logout()
      }

      if (json.errors) {
        const { message } = json.errors[0] || {}
        throw new Error(message || 'Error…')
      }

      return json.data
    }
  }
