import { Theme } from '@mui/material'
import MuiStepper from '@mui/material/Stepper'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { BusinessAssetsFilingStep } from '../../models/api'
import {
  goToAccountInformation,
  goToLeasedAssetList,
  goToMultipleAccounts,
  goToPropertyAssetList,
  goToSummaryAndSignature,
} from '../../utils/routeActions'

import Step from './Step'

export interface Props {
  activeStep: number
  isAccountInformationComplete?: boolean
  isPropertyAssetListComplete?: boolean
  isAssociatedAccountsComplete?: boolean
  isLeasedAssetListComplete?: boolean
  isSummaryAndSignatureComplete?: boolean
}

export default function Stepper(props: Props): JSX.Element {
  const { t } = useTranslation()
  const { sessionContext } = useAuth()
  const navigate = useNavigate()

  const classes = useStyles()

  const completedSteps = React.useMemo(() => {
    return sessionContext?.completedSteps.reduce(selectCompletedStep, {})
  }, [sessionContext?.completedSteps])

  const isFirstStepComplete = completedSteps?.isAccountInformationComplete
  const isSecondStepComplete = isFirstStepComplete && completedSteps?.isAssociatedAccountsComplete
  const isThirdStepComplete = isSecondStepComplete && completedSteps?.isPropertyAssetListComplete
  const isFourthStepComplete = isThirdStepComplete && completedSteps?.isLeasedAssetListComplete
  const isFifthStepComplete = isFourthStepComplete && completedSteps?.isSummaryAndSignatureComplete

  const onAccountInformationClick = (): void => {
    goToAccountInformation(navigate)
  }

  const onMultipleAccountsClick = (): void => {
    goToMultipleAccounts(navigate)
  }

  const onPropertyAssetListClick = (): void => {
    goToPropertyAssetList(navigate)
  }

  const onLeasedAssetListClick = (): void => {
    goToLeasedAssetList(navigate)
  }

  const onSummaryAndSignatureClick = (): void => {
    goToSummaryAndSignature(navigate)
  }

  return (
    <MuiStepper activeStep={props.activeStep} alternativeLabel nonLinear classes={classes}>
      <Step
        label={t('accountInformation')}
        activeStep={props.activeStep}
        thisStep={0}
        isCompleted={isFirstStepComplete}
        onClick={onAccountInformationClick}
      />
      <Step
        label={t('associatedAccounts')}
        activeStep={props.activeStep}
        thisStep={1}
        isCompleted={isSecondStepComplete}
        isDisabled={!isFirstStepComplete}
        isOptional
        onClick={onMultipleAccountsClick}
      />
      <Step
        label={t('propertyAssetList')}
        activeStep={props.activeStep}
        thisStep={2}
        isCompleted={isThirdStepComplete}
        isDisabled={!isSecondStepComplete}
        onClick={onPropertyAssetListClick}
      />
      <Step
        label={t('leasedAssetList')}
        activeStep={props.activeStep}
        thisStep={3}
        isCompleted={isFourthStepComplete}
        isDisabled={!isThirdStepComplete}
        isOptional
        onClick={onLeasedAssetListClick}
      />
      <Step
        label={t('summaryAndSignature')}
        activeStep={props.activeStep}
        thisStep={4}
        isCompleted={isFifthStepComplete}
        isDisabled={!isFourthStepComplete}
        onClick={onSummaryAndSignatureClick}
      />
    </MuiStepper>
  )
}

type CompletedSteps = Partial<Props>

function selectCompletedStep(alreadyCompleted: CompletedSteps, step: BusinessAssetsFilingStep): CompletedSteps {
  switch (step) {
    case BusinessAssetsFilingStep.AssociatedAccounts: {
      return { ...alreadyCompleted, isAssociatedAccountsComplete: true }
    }

    case BusinessAssetsFilingStep.PropertyAssetList: {
      return { ...alreadyCompleted, isPropertyAssetListComplete: true }
    }

    case BusinessAssetsFilingStep.LeasedAssetList: {
      return { ...alreadyCompleted, isLeasedAssetListComplete: true }
    }

    case BusinessAssetsFilingStep.SummaryAndSignature: {
      return { ...alreadyCompleted, isSummaryAndSignatureComplete: true }
    }
  }

  return { ...alreadyCompleted, isAccountInformationComplete: true }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 30,
    padding: theme.spacing(3),
    backgroundColor: 'inherit',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}))
